a {
  color: inherit;
}

html,
body {
  height: 100%;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  /* background-color: rgba(32, 40, 50, 0.9); */
  /* background-color: #202832; */
}

.App {
  overflow-y: hidden;
  text-align: center;
  height: 100vh;
  width: 100vw;
  background-color: #202832;
}

@keyframes glowingWarning {
  0% {
    background-color: transparent;
    box-shadow: 0 0 5px #ffc107;
  }
  50% {
    background-color: rgba(255, 193, 7, 0.1);
    box-shadow: 0 0 15px #ffc107;
    color: black;
  }
  100% {
    background-color: rgba(255, 193, 7, 0.2);
    box-shadow: 0 0 7px #ffc107;
    color: black;
  }
}

@keyframes glowingGreen {
  0% {
    background-color: transparent;
    box-shadow: 0 0 5px #1dbf73;
  }
  50% {
    background-color: rgba(29, 191, 115, 0.1);
    box-shadow: 0 0 20px #1dbf73;
    color: black;
  }
  100% {
    background-color: rgba(29, 191, 115, 0.2);
    box-shadow: 0 0 7px #1dbf73;
    color: black;
  }
}

@keyframes pulse {
  from { transform: scale(0.8); }
  to { transform: scale(1.2); }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.horizontal-line {
  display: flex;
  flex-direction: row;
}

.horizontal-line:before,
.horizontal-line:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}

.horizontal-line:before {
  margin-right: 10px
}

.horizontal-line:after {
  margin-left: 10px
}

.black-text {
  color: black !important;
}

.white-text {
  color: white !important;
}

.warning-text {
  color: #FFAE00 !important;
}

.unavailable-text {
  color: #BF2D32 !important;
}

.green-text {
  color: #1dbf73 !important;
}

.commonproblems,
.aboutus {
  height: 100%;
  min-height: 100vh;
  font-size: 1rem;
  background-color: rgba(32, 40, 50, 1.0);
  color: white;
}

.accountactivation,
.errorpage,
.resetpassword {
  height: 100%;
  min-height: 95vh;
  font-size: 0.9rem;
  background-color: rgba(32, 40, 50, 1.0);
  color: white;
  text-align: center;
}

.facebook-login-button-custom {
  font-size: 0.7rem;
  background-color: #4267b2;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
}

.map {
  overflow-y: hidden;
  /* position: relative; */
  position: fixed;
  width: 100%;
  height: 91%;
  display: flex;
  justify-content: center;
}

.map-navbar {
  height: 5vh;
  font-size: 0.7rem;
  background-color: #202832;
}

.map-navbar-lg {
  height: 7vh;
  font-size: 0.7rem;
  background-color: #202832;
}

.map-navbar .navbar-text {
  color: white !important;
}

.map-navbar-lg .navbar-text {
  color: white !important;
}

/* .map-with-footer{
  height: 91%; 
}

.map-no-footer{
  height: 94%; 
} */

.leaflet-bottom {
  margin-bottom: 30px;
}

/* .leaflet-popup-content{
  margin: 12px 18px ;
} */

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background-color: rgba(32, 40, 50, 1.0);
  /* rgba(29, 191, 115, 1.0);  */
  /* #E8E8E8 */
  /* f6f6f6 */
  /* #ECE3B1; */
}

.leaflet-geosearch-bar {
  z-index: 900 !important;
}

.leaflet-popup-pane {
  z-index: 902;
}

.popup-element-div {
  font-size: 0.9rem;
  cursor: pointer;
  color: white;
}

.popup-element-div-bordered {
  border: solid 0.9px;
  border-radius: 0.25rem;
  padding: 8px;
}

.popup-element-div-green {
  color: #1dbf73;
}

.popup-element-div-white {
  border-color: white;
  color: white;
  box-shadow: 0.3px 0.3px 0.1px white;
}

.popup-element-div-warning {
  border-color: #FFAE00;
  color: #FFAE00;
  box-shadow: 0.3px 0.3px 0.1px #FFAE00;
}

.popup-element-div-red {
  border-color: #BF2D32;
  color: #BF2D32;
  font-weight: 900;
  box-shadow: 0.9px 0.9px 0.9px #BF2D32;
}

.popup-element-div-hidden {
  border-color: #202832;
  color: white;
  box-shadow: 0.3px 0.3px 0.1px #202832;
}

.red {
  color: #BF2D32;
}

.companyLogo {
  height: 10vh;
}

.companyLogoModalHeader {
  height: 5vh;
}

.companyLogoPopupHeader {
  background-size: cover;
  background-repeat: 'no-repeat';
  height: 5vh;
  min-height: 5vh;
  width: auto;
}

.modalsBody {
  background-color: rgba(29, 191, 115, 0.2);
}

.modalsBody .form-select,
.form-control {
  background-color: rgba(29, 191, 115, 0.2) !important;
}

.modalShowMarkerDetails p {
  font-size: 12px;
}

.modalShowMarkerDetailsImg {
  background-size: cover;
  background-repeat: 'no-repeat';
}

.searchOverlay {
  position: absolute;
  top: 8vh;
  margin: 0 auto;
  z-index: 901;
}

.searchOverlay-btn {
  background-color: rgba(32, 40, 50, 0.8) !important;
  color: #1dbf73 !important;
  font-size: 1rem;
}

.card-title {
  font-size: 0.8rem;
}

.offcanvas-closebutton {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #1dbf73;
  background: transparent url('static/Icons/close.svg') center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: .5
}

.offcanvas-header {
  /* background-color: rgba(29, 191, 115, 0.8); */
  background-color: #202832;
  color: white;
}

.offcanvas-header .btn-close {
  color: #1dbf73;
}

.offcanvas .card-header {
  background-color: rgba(32, 40, 50, 0.9);
  color: #1dbf73;
  /* background-color: rgba(29, 191, 115, 0.3); */
}

.offCanvasUserProfile {
  color: white;
}

.toast-header {
  /* background-color: rgba(29, 191, 115, 0.6); */
  background-color: #202832;
  /* background-color: #1dbf73; */
}

.toast-body {
  /* background-color: rgba(32, 40, 50, 0.4); */
  background-color: rgba(29, 191, 115, 0.3);
  /* background-color: #1dbf73; */
}

.shareOnSocials-toast {
  z-index: 10000;
  margin-top: 5%;
}

.shareOnSocials-toast .toast-body {
  background-color: white;
}


.loginToast {
  z-index: 10000;
  margin-bottom: 5%;
}

.loginIcon {
  color: #ffc107;
}

.signedin {
  text-decoration: none !important;
  /* color:#1dbf73 !important; */
}

.settings-menu {
  min-width: 7rem;
  width: 7rem;
  font-size: 0.8rem;
  background-color: #202832;
}

/* .settings-menu .dropdown-item {
  color: #1dbf73 !important;
} */

.offcanvasButtonsLessPadding {
  padding: 0.25rem 0.1rem;
}

.report-image-thumbnail {
  width: 75%;
  height: auto;
}

.report-image-modal {
  width: 100%;
  height: auto;
}

.report-takepicture {
  width: 100%;
  height: auto;
}

.report-image-preview {
  width: 30%;
  height: auto;
}

.charger-tooltip{
  border-radius: 50%;
  font-size: 0.65rem;
  padding: 0.5px 3px;
}

.charger-tooltip-available{
  background-color: #1dbf73;
  border: 1px solid #1dbf73;
  box-shadow: 0 1px 3px rgba(29, 191, 115, 0.4);
}

.charger-tooltip-busy{
  background-color: #9F9F9F;
  border: 1px solid #9F9F9F;
  box-shadow: 0 1px 3px rgb(127, 127, 127, 0.4);
}

/*Hide tooltip arrow*/
.leaflet-tooltip-top:before, 
.leaflet-tooltip-bottom:before, 
.leaflet-tooltip-left:before, 
.leaflet-tooltip-right:before {
    border: none !important;
}

@media (max-width: 330px) {
  .leaflet-popup {
    max-width: 85vw;
    max-width: 80vw;
  }
}

@media(min-width: 330px) and (max-width: 370px) {
  .leaflet-popup {
    max-width: 75vw;
    min-width: 70vw;
  }
}

@media(min-width: 370px) and (max-width: 400px) {
  .leaflet-popup {
    max-width: 70vw;
    min-width: 65vw;
  }
}

@media(min-width: 400px) and (max-width: 500px) {
  .leaflet-popup {
    max-width: 65vw;
    min-width: 60vw;
  }
}

@media (min-width: 500px) and (max-width: 700px) {
  .leaflet-popup {
    max-width: 50vw;
    min-width: 45vw;
  }
}

@media (min-width: 700px) and (max-width: 900px) {
  .leaflet-popup {
    max-width: 45vw;
    min-width: 40vw;
  }
}

@media (min-width: 900px) and (max-width: 1100px) {
  .leaflet-popup {
    max-width: 35vw;
    min-width: 30vw;
  }
}

@media (min-width: 1100px) and (max-width: 1200px) {
  .leaflet-popup {
    max-width: 30vw;
    min-width: 25vw;
  }
}

@media (min-width: 1200px) {
  .leaflet-popup {
    max-width: 20vw;
    min-width: 18vw;
  }
}

@media (max-width: 500px) {
  .offCanvasUserFavoriteChargers {
    width: 65vw;
  }

  .offCanvasUserReports {
    width: 65vw;
  }

  .offCanvasChargerStatusInfo {
    width: 65vw;
  }

  .offCanvasUserProfile {
    width: 68vw;
  }

  .offcanvas-cards {
    width: 56vw;
  }

  .offcanvas-cards-inner {
    width: 52vw;
  }

  .offcanvas h5 {
    font-size: 1rem;
  }

  .offcanvas .card-text {
    font-size: 0.8rem;
  }

  .offcanvasButtons {
    font-size: 0.6rem;
  }

  .modal-footer button {
    font-size: 0.8rem;
  }

  .chargeprob-banner {
    background: url('static/images/ChargeProb_banner_cropped_slim_small.jpg') no-repeat center;
    background-size: auto;
    height: 15vh;
    width: auto;
  }
}

/* here the rules for windows between 500px and 900px */
@media (min-width: 500px) and (max-width: 900px) {
  .offCanvasUserFavoriteChargers {
    width: 50w;
  }

  .offCanvasUserReports {
    width: 50vw;
  }

  .offCanvasChargerStatusInfo {
    width: 50vw;
  }

  .offCanvasUserProfile {
    width: 52vw;
  }

  .offcanvas-cards {
    width: 43vw;
  }

  .offcanvas-cards-inner {
    width: 32vw;
  }

  .offcanvasButtons {
    font-size: 0.7rem;
  }

  .modalShowMarkerDetails {
    width: 65vw;
  }

  .chargeprob-banner {
    background: url('static/images/ChargeProb_banner_cropped_slim_medium.jpg') no-repeat center;
    background-size: auto;
    height: 35vh;
    width: auto;
  }
}

@media (min-width: 900px) {
  .offCanvasUserFavoriteChargers {
    width: 25vw;
  }

  .offCanvasUserReports {
    width: 25vw;
  }

  .offCanvasChargerStatusInfo {
    width: 25vw;
  }

  .offCanvasUserProfile {
    width: 27vw;
  }

  .offcanvas-cards {
    width: 20vw;
  }

  .offcanvas-cards-inner {
    width: 14vw;
  }

  .offcanvasButtons {
    font-size: 0.8rem;
  }

  .modalShowMarkerDetails {
    width: 45vw;
  }

  .chargeprob-banner {
    background: url('static/images/ChargeProb_banner_cropped_slim.jpg') no-repeat center;
    background-size: auto;
    height: 35vh;
    width: auto;
  }
}

.legend {
  padding: 1vh 0.8vw;
  font-size: 0.7rem;
  /* Arial, Helvetica, sans-serif; */
  background: white;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-align: left;
  line-height: 2vh;
  color: #555;
  /* margin-bottom: 3vh; */
}

.legendInfoModal p {
  margin-bottom: 2px;
}

.locateme {
  /* padding: 0.3vh 0.4vh;
  font: 0.7rem Arial, Helvetica, sans-serif; 
  background: rgba(255, 255, 255, 0.8);*/
  background: rgba(32, 40, 50, 0.9);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 36px;
  height: 36px;
  cursor: pointer;
}

.locatemeIcon {
  width: 36px;
  height: 36px;
  border: none;
  background: url('static/Icons/gps.png') no-repeat center;
}

.userIcon {
  background: url('static/Icons/user_green_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

.userIcon-sm {
  background: url('static/Icons/user_green_18px.png');
  height: 18px;
  width: 18px;
  display: block;
}

.CPIcon {
  background: url('static/images/CP_icon_white_32px.png');
  width: 32px;
  height: 32px;
  display: block;
}

.userReportIcon {
  background: url('static/Icons/userreport_white_32px.png');
  height: 32px;
  width: 32px;
  display: block;
}

.userFeedbackIcon {
  background: url('static/Icons/feedback_white_32px.png');
  height: 32px;
  width: 32px;
  display: block;
}

.userFavChargerIcon {
  background: url('static/Icons/heart_white_32px.png');
  height: 32px;
  width: 32px;
  display: block;
}

.userProfileIcon {
  background: url('static/Icons/userprofile_white_32px.png');
  height: 32px;
  width: 32px;
  display: block;
}

.rightArrowWhiteIcon {
  background: url('static/Icons/right_arrow_white_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

.rightArrowBlackIcon {
  background: url('static/Icons/right_arrow_black_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

.rightArrowGreenIcon {
  background: url('static/Icons/right_arrow_green_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

.rightArrowWarningIcon {
  background: url('static/Icons/right_arrow_warning_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

.rightArrowRedIcon {
  background: url('static/Icons/right_arrow_red_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

.socketIcon {
  background: url('static/Icons/socket_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

.socketIcon-white-small {
  background: url('static/Icons/socket_white_16px.png');
  height: 16px;
  width: 16px;
  display: block;
}

.socketIcon-white {
  background: url('static/Icons/socket_white_24px.png') no-repeat;
  height: 28px;
  width: 28px;
  display: block;
}

.commentsIcon-small {
  background: url('static/Icons/comments_16px.png');
  height: 16px;
  width: 16px;
  display: block;
}

.commentsIcon {
  background: url('static/Icons/comments_24px.png') no-repeat;
  height: 28px;
  width: 28px;
  display: block;
}

.likeIcon {
  background: url('static/Icons/like_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

.likeIcon-xs {
  background: url('static/Icons/like_12px.png');
  height: 12px;
  width: 12px;
  display: block;
}

.likeIcon-small {
  background: url('static/Icons/like_16px.png');
  height: 16px;
  width: 16px;
  display: block;
}

.likeIcon-big {
  background: url('static/Icons/like_32px.png');
  height: 32px;
  width: 32px;
  display: block;
}

.dislikeIcon {
  background: url('static/Icons/dislike_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

.dislikeIcon-xs {
  background: url('static/Icons/dislike_12px.png');
  height: 12px;
  width: 12px;
  display: block;
}

.dislikeIcon-small {
  background: url('static/Icons/dislike_16px.png');
  height: 16px;
  width: 16px;
  display: block;
}

.dislikeIcon-big {
  background: url('static/Icons/dislike_32px.png');
  height: 32px;
  width: 32px;
  display: block;
}

.evConnectorIcon {
  background: url('static/Icons/ev_connector_32px.png');
  height: 32px;
  width: 26px;
  display: block;
}

.parkingIcon {
  background: url('static/Icons/parking_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

.capabilitiesIcon {
  background: url('static/Icons/evses_capabilities_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

.rfidIcon {
  background: url('static/Icons/rfid.png');
  height: 24px;
  width: 24px;
  display: block;
}

.creditcardIcon {
  background: url('static/Icons/credit_card_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

.evsereservableIcon {
  background: url('static/Icons/evse_reservable_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

.evseremotestartstopIcon {
  background: url('static/Icons/evse_remote_startstop_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

.evcarIcon {
  background: url('static/Icons/ev_car_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

.infoIcon-xs {
  background: url('static/Icons/info_16px.png');
  height: 16px;
  width: 16px;
  display: block;
}

.infoIcon {
  background: url('static/Icons/info_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

/* .notfoundIcon-xs {
  background: url('static/Icons/notfound_16px.png');
  height: 16px;
  width: 16px;
  display: block;
}

.notfoundIcon {
  background: url('static/Icons/notfound_24px.png');
  height: 24px;
  width: 24px;
  display: block;
} */

.plannedmaintenanceIcon {
  background: url('static/Icons/planned_maintenance_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

.disruptionAreaIcon {
  background: url('static/Icons/disruption_area_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

.progressIcon {
  background: url('static/Icons/progress_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

.searchChargerIcon {
  background: url('static/Icons/searchCharger_64px.png');
  height: 64px;
  width: 64px;
  display: block;
}

.reportProblemIcon {
  background: url('static/Icons/reportProblem_64px.png');
  height: 64px;
  width: 64px;
  display: block;
}

.checkStatusIcon {
  background: url('static/Icons/checkStatus_64px.png');
  height: 64px;
  width: 64px;
  display: block;
}

.notificationIcon {
  background: url('static/Icons/notification_64px.png');
  height: 64px;
  width: 64px;
  display: block;
}

.reportWarningIcon {
  background: url('static/Icons/reportWarning_64px.png');
  height: 64px;
  width: 64px;
  display: block;
}

.reportWarningGreenIcon {
  background: url('static/Icons/reportWarningGreen_64px.png');
  height: 64px;
  width: 64px;
  display: block;
}

.reportSlowChargingIcon {
  background: url('static/Icons/reportSlow_64px.png');
  height: 64px;
  width: 64px;
  display: block;
}

.reportSlowChargingGreenIcon {
  background: url('static/Icons/reportSlowGreen_64px.png');
  height: 64px;
  width: 64px;
  display: block;
}

.reportStartStopIcon {
  background: url('static/Icons/reportTrafficLight_64px.png');
  height: 64px;
  width: 64px;
  display: block;
}

.reportStartStopGreenIcon {
  background: url('static/Icons/reportTrafficLightGreen_64px.png');
  height: 64px;
  width: 64px;
  display: block;
}

.reportOtherProblemIcon {
  background: url('static/Icons/reportOtherProblem_64px.png');
  height: 64px;
  width: 64px;
  display: block;
}

.reportOtherProblemGreenIcon {
  background: url('static/Icons/reportOtherProblemGreen_64px.png');
  height: 64px;
  width: 64px;
  display: block;
}

.noLocationPermissionsAlert {
  position: absolute;
  z-index: 1000;
  top: 8vh;
  font-size: 0.7rem;
}

.noLocationPermissionsAlertResp .alert-heading {
  font-size: 0.8rem;
}

.legendAvailable {
  background: url('static/Icons/green-charger_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

.legendUnavailable {
  background: url('static/Icons/red-charger_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

.legendWarning {
  background: url('static/Icons/warning-charger_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

.legendHalfAvailable {
  background: url('static/Icons/red-green-charger_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

.legendUnknown {
  background: url('static/Icons/unknown-charger_24px.png');
  height: 24px;
  width: 24px;
  display: block;
}

.languageEN {
  background-image: url('static/Icons/eng_14px.png');
  background-size: auto;
}

.languageNL {
  background-image: url('static/Icons/nl_14px.png');
  background-size: auto;
}

.select-language-dark {
  background-color: #202832;
  color: white;
  /* color: #1dbf73; */
  font-size: 0.5rem;
  padding-left: 0.3rem;
}

.select-language-light {
  color: black;
  font-size: 0.5rem;
  padding-left: 0.3rem;
}

.select-language {
  font-size: 0.6rem;
}

.select-language option {
  padding: 0;
}

.chargeprob-green {
  color: #1dbf73 !important;
}

.chargeprob-darkgreen {
  color: #202832 !important;
}

.chargeprob-background-green {
  background-color: #1dbf73 !important;
}

.chargeprob-background-darkgreen {
  background-color: rgba(32, 40, 50, 1.0) !important;
}

.chargeprob-button-green {
  background-color: #1dbf73 !important;
  border-color: #1dbf73 !important;
  color: #202832 !important;
}

.chargeprob-button-green-white {
  background-color: #1dbf73 !important;
  border-color: #1dbf73 !important;
  color: white !important;
}

.chargeprob-button-outline-green {
  background-color: transparent;
  border-color: #1dbf73 !important;
  color: #1dbf73 !important;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition-duration: 0.15s, 0.15s, 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  transition-delay: 0s, 0s, 0s, 0s;
  transition-property: color, background-color, border-color, box-shadow;
}

.chargeprob-button-outline-green:hover {
  background-color: #1dbf73;
  color: white !important;
}

.chargeprob-button-outline-green:active {
  background-color: transparent;
  border-color: #1dbf73 !important;
  color: #1dbf73 !important;
}

.chargeprob-button-outline-green:focus {
  background-color: transparent;
  border-color: #1dbf73 !important;
  color: #1dbf73 !important;
}

.chargeprob-button-outline-warning {
  background-color: transparent;
  border-color: #ffc107 !important;
  color: #ffc107 !important;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition-duration: 0.15s, 0.15s, 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  transition-delay: 0s, 0s, 0s, 0s;
  transition-property: color, background-color, border-color, box-shadow;
}

.chargeprob-button-outline-warning:hover {
  background-color: #ffc107;
  color: #ffc107 !important;
}

.chargeprob-button-outline-warning:active {
  background-color: transparent;
  border-color: #ffc107 !important;
  color: #ffc107 !important;
}

.chargeprob-button-outline-warning:focus {
  background-color: transparent;
  border-color: #ffc107 !important;
  color: #ffc107 !important;
}

.reportIncident-button-glowing {
  animation: glowingWarning 1300ms infinite;
}

.sendReport-button-glowing {
  animation: glowingGreen 1300ms infinite;
}

.searchHere-badge-pulsing {
  animation: pulse 1s infinite ease-in-out alternate;
}

.chargeprob-aboutus {
  /* font-family: 'Poppins', Arial, Helvetica, sans-serif; */
  background-color: rgba(32, 40, 50, 1.0);
  color: white;
  font-size: 1rem;
}

.chargeprob-homepage {
  /* font-family: 'Poppins', Arial, Helvetica, sans-serif; */
  background-color: rgba(32, 40, 50, 1.0);
  color: white;
  font-size: 1rem;
  height: auto;
}

.homepagediv {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.generalStatsDivs {
  border-style: solid;
  border-width: 2px;
  border-color: #1dbf73 !important;
  border-radius: 0.25rem;
  box-shadow: 2px 2px #888888;
  background-color: #1dbf73;
  color: black;
  padding: 0.5em;
  font-size: 0.8rem;
  /* flex: 1; */
  /* min-height: 120px; */
  display: flex;
}

.generalStatsCards {
  background-color: #1dbf73;
  font-size: 0.8rem;
  padding: 0.1em !important;
}

.check {
  display: flex;
  align-items: flex-end;
}